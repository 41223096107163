import React from "react";

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container } from '../../components/container'
import { Wrapper } from '../../components/wrapper';

const IndexPage = () => {
  return (
    <Layout>

      <SEO title="Store" />

      <Wrapper>

      </Wrapper>

    </Layout>
  );
}
export default IndexPage;
